<template>

    <div class="videoBg" >
        <transition name="fadeSections">
            <video v-if="$route.name == 'Home'" id="homeVidBG" muted autobuffer autoplay loop>
                <source id="mp4" src="/ui-video/Home_BG.mp4" type="video/mp4">
            </video>
        </transition>
        <transition name="fadeSections">
            <div class="videoBg" style="background-color: rgb(0,0,0); opacity: 0.5;" v-if="showFadeSections"></div>
        </transition>
        <transition name="fadeSections">
            <video v-if="$route.name == 'ZenoTOF 7600'" id="homeVidBG" muted autobuffer autoplay loop>
                <source id="mp4" src="/ui-video/ZenoTOF_BG.mp4" type="video/mp4">
                <!-- <source id="mp4" src="/ui-video/1071154411-preview.mp4" type="video/mp4"> -->
            </video>
        </transition>
        <transition name="fadeSections">
            <video v-if="$route.name == 'OneOmics'" id="homeVidBG" muted autobuffer autoplay loop>
                <source id="mp4" src="/ui-video/OneOmics_BG.mp4" type="video/mp4">
            </video>
        </transition>
        <transition name="fadeSections">
            <video v-if="$route.name == 'BioPhase'" id="homeVidBG" style="background-color: rgb(0,0,0); opacity: 0.4;" muted autobuffer autoplay loop>
                <source id="mp4" src="/ui-video/BioPhase_BG.mp4" type="video/mp4">
            </video>
        </transition>
    </div>
    <div id="frame" @keyup.space="vidControl">
        <div id="companyLogo" style="position: relative;">
            <img src="/imgs/sciex-logo-white.svg" alt="Sciex">
        </div>
        <div id="border" @mousemove="mousePos">  

        
        <!-- Page Content -->
        <router-view v-slot="{ Component }">
            <transition name="fadeSections">
            <component :is="Component" />
            </transition>
        </router-view>
        

        <!-- Always On Content -->
        <router-link @click="navJump" to="/">
            <div id="topLogo">
                <div class="icon">
                    <img src="/imgs/SciexLens_icon.svg" alt="Sciex Lens">
                </div>
                <div class="title">
                    SCIEX <em>LENS</em>
                </div>
            </div>
        </router-link>

        <div id="mouse"> 
            X: 00.0000{{mousePosition.x}}&nbsp;&nbsp;|&nbsp;&nbsp;Y: 00.0000{{mousePosition.y}} 
            <div class="sideGraphic">
                <div class="object">
                </div>
            </div>
        </div>
                
        <nav-bar></nav-bar>

    </div>


    </div>

    <!-- Video Modal -->
    <transition name="fadeSections">
        <div v-if="displayVideo.show" id="videoViewer">
            <div class="videoCloserBG" @click="closeVideo"></div>
            <div class="videoContainer">
                <button class="closeBtn" @click="closeVideo" aria-label="Close Video">
                    <span>
                        <span class="button-left"></span>
                        <span class="button-right"></span>
                    </span>
                </button>
                <video ref="storyVid" id="storyVid" width="700" autobuffer controls autoplay>
                    <source id="mp4" :src="displayVideo.vid" type="video/mp4">
                </video>
            </div>
        </div>
    </transition>

</template>

<script>
import navBar from "./components/navBar.vue";

export default {
  name: "app",
  components: {
    navBar
  },
  data: function() {
      return {
          mousePosition: {
              x: 0,
              y: 0
          },
          topBoundary: 0
      }
  },
  computed: {
      displayVideo() {
          return this.$store.state.displayVideo;
      },
      currentRoute() {
          return this.$route.name;
      },
      mouseDisplay: function() {
          return this.mousePosition
      },
      appTop: function() {
          return this.topBoundary
      },
      showFadeSections() {
          if (this.currentRoute) {
              return true
          }
          return false;
      }
  },
  methods: {

      vidControl() {
          if (this.$refs.storyVid.paused == true) {
            this.$refs.storyVid.play();
          } else {
              this.$refs.storyVid.pause();
          }
      },

      mousePos: function(e) {
        this.$store.commit("mousePos", {
            x: e.x,
            y: e.y
        });

        this.mousePosition = {
            x: Math.round(100.6 * e.x),
            y: Math.round(100.6 * e.y)
        };

      },
      navJump: function() {
        this.$store.commit("resetLensPostition");
        if (this.$store.state.showNav) {
          this.$store.commit("toggleNav");
        }
      },
      closeVideo() {
          //Stop the video
          this.$refs.storyVid.pause();
          this.$refs.storyVid.currentTime = 0;
          
          // Close the video overlay
          this.$store.commit("toggleVideo");

      }
  }
};
</script>



<style>

/* Fonts */
@import url(//hello.myfonts.net/count/3dac27);
@font-face{
    font-family:Geogrotesque-Rg;
    src:url(/fonts/webFonts/GeogrotesqueRg/font.woff2) format("woff2"),url(/fonts/webFonts/GeogrotesqueRg/font.woff) format("woff")
}
@font-face{
    font-family:Geogrotesque-SmBd;
    src:url(/fonts/webFonts/GeogrotesqueSmBd/font.woff2) format("woff2"),url(/fonts/webFonts/GeogrotesqueSmBd/font.woff) format("woff")
}
@font-face{
    font-family:Geogrotesque-SmBdIt;
    src:url(/fonts/webFonts/GeogrotesqueSmBdIt/font.woff2) format("woff2"),url(/fonts/webFonts/GeogrotesqueSmBdIt/font.woff) format("woff")
}
@font-face{
    font-family:Geogrotesque-RgIt;
    src:url(/fonts/webFonts/GeogrotesqueRgIt/font.woff2) format("woff2"),url(/fonts/webFonts/GeogrotesqueRgIt/font.woff) format("woff")
}
@font-face{
    font-family:Geogrotesque-Lt;
    src:url(/fonts/webFonts/GeogrotesqueLt/font.woff2) format("woff2"),url(/fonts/webFonts/GeogrotesqueLt/font.woff) format("woff")
}



*{
    box-sizing:border-box
}
body{
    padding:0;
    margin:0;
    width:100%;
    height:100vh;
    background-color:#000
}
#companyLogo{
    text-align:right;
    padding:.2rem;
    margin-top:-1rem
}
#app{
    width:100%;
    min-width:1200px;
    height:100%;
    min-height:720px;
    padding:0 2rem;
    display:flex;
    align-items:center;
    justify-content:center;
    font:16px/1.15 Geogrotesque-Lt,Helvetica,Arial,sans-serif;
    -webkit-font-smoothing:antialiased;
    -moz-osx-font-smoothing:grayscale
}
h1,h2,h3,h4,h5,h6{
    font-weight:400;
    margin:0
}
.zenotof7600 .gradBG,
.zenoTOFbg,
a.zenoTOF {
    background:#6cba33;
    background:linear-gradient(52deg,#249743 10%,#6cba33 46%,#9cd32c 90%)
}
.oneomics .gradBG,
.oneomicsbg,
a.oneomics {
    background:#0499ae;
    background:linear-gradient(52deg,#016489 10%,#0499ae 46%,#08ccc6 90%)
}

.biophase .gradBG,
.biophasebg,
a.biophase {
    background:#002F5B;
    background:linear-gradient(52deg,#011B34 10%,#002F5B 46%,#0060C0 90%)
}
.biophase .storyText p {
    color: #ffffff;;
}

#frame{
    padding:0 0 2rem 0;
    width:calc(1200px - 4rem);
    min-width:calc(1200px - 4rem);
    height:calc(716px - 4rem)
}
#border{
    position:relative;
    border:2px solid #fff;
    background-color:rgba(0,0,0,0.3);
    width:100%;
    height:100%;
    overflow:hidden
}
#topLogo{
    position:absolute;
    top:0;
    left:0;
    width:240px;
    height:50px;
    border-right:2px solid #fff;
    border-bottom:2px solid #fff;
    background-color:#000;
    display:flex;
    align-items:center;
    z-index:200
}
#topLogo .icon{
    height:100%;
    background-color:#fff;
    width:50px
}
#topLogo .icon img {
    width: 100%;
}
#topLogo .title{
    font-size:2rem;
    padding:.5rem 1rem;
    color:#fff
}
#topLogo .title em{
    font-family:Geogrotesque-SmBd,Helvetica,Arial,sans-serif;
    font-style:normal
}
.fade-enter-active, .fade-leave-active{
    transition:opacity .5s ease
}
.fade-enter-from, .fade-leave-to{
    opacity:0
}

.fadeSections-enter-active, 
.fadeSections-leave-active {
    transition:opacity 600ms ease
}


.fadeSections-enter-from, 
.fadeSections-leave-to {
    opacity:0
}

#mouse{
    position:absolute;
    top:85%;
    left:10px;
    transform-origin:0;
    transform:rotate(-90deg);
    font-size:11px;
    width:200px;
    color:#fff
}
#mouse .sideGraphic{
    border:1px solid #fff;
    border-bottom:none;
    position:relative;
    height:4px
}
#mouse .object{
    position:absolute;
    top:1px;
    left:0;
    border:1px solid #0084a9;
    width:50%;
    -webkit-animation:graphic 6s infinite;
    animation:graphic 6s infinite
}
@-webkit-keyframes graphic{
    0%{
        width:50%;
        left:0
    }
    25%{
        width:10%;
        left:80%
    }
    50%{
        width:50%;
        left:20%
    }
    75%{
        width:70%;
        left:25%
    }
    to{
        width:50%;
        left:0
    }
}
@keyframes graphic{
    0%{
        width:50%;
        left:0
    }
    25%{
        width:10%;
        left:80%
    }
    50%{
        width:50%;
        left:20%
    }
    75%{
        width:70%;
        left:25%
    }
    to{
        width:50%;
        left:0
    }
}



/* Close Button */
.closeBtn{
    position:fixed;
    top: 0.6rem;
    right: 1.25rem;
    background:none;
    width:30px;
    height:30px;
    border:0;
    outline:0 solid hsla(0,0%,100%,.4);
    background:rgba(0,0,0,.5);
    font-size: 0.8rem;
    font-family:Geogrotesque-Rg,Helvetica,sans-serif;
    color: #FFFFFF;
    cursor: pointer;
    z-index: 1;
}
.closeBtn:hover {
    background:rgba(255,255,255,.4);
    color: #000000;
}
.closeBtn:focus{
    outline:2px solid hsla(0,0%,100%,.3);
    background:hsla(0,0%,100%,.3)
}
.closeBtn span{
    display:block;
    color:#fff
}
.closeBtn span.button-left,
.closeBtn span.button-right{
    border-top:1px solid;
    transform:rotate(45deg);
    transform-origin:center;
    position:absolute;
    top:15px;
    width:12px;
    left:5px;
    transition:all .3s ease-in-out
}
.closeBtn span.button-right{
    transform:rotate(-45deg);
    left:13px
}
.closeBtn span.button-left,
.closeBtn span.button-right{
    top:15px;
    width:20px;
    left:5px
}





/* Video BGs */
.videoBg {
    position: fixed;
    top:0;
    left:0;
    opacity: 0.45;
    width: 100%;
    height: 100%;

    overflow: hidden;
}
.videoBg video {
    height: 100%;
    width: calc(100vh * 16 / 9);
    min-width: 100%;
    min-height: calc(100vw * 9 / 16);
}




#videoViewer {
    position:fixed;
    background-color: rgba(0,0,0,.8);
    width: 100%;
    height: 100%;
    z-index: 500;
    display: flex;
    align-items: center;
    justify-content: center;
}

#videoView video {
    pointer-events: all;
    display: block;
}

.videoContainer {
    box-shadow: 0 0 20px rgba(0,0,0,0.5);
    line-height: 0;
    position: relative;
    z-index: 1;
}

.videoContainer .closeBtn {
    position: absolute;
    right: -2.75rem;
    top: -2.75rem;
}
.videoCloserBG {
    position: absolute; 
    width: 100%; 
    height: 100%; 
    z-index:0
}





</style>
