import { createRouter, createWebHistory } from "vue-router";
import Home from "../views/Home.vue";

const routes = [
  {
    path: "/",
    name: "Home",
    class: "home",
    component: Home,
    meta: {
      productName: "Home"
    },
  },
  {
    path: "/biophase",
    name: "BioPhase",
    class: "biophase",
    meta: {
      productName: "BioPhase 8800 system"
    },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "products" */ "../views/products.vue")
  },
  {
    path: "/zenotof-7600",
    name: "ZenoTOF 7600",
    class: "zenoTOF",
    meta: {
      productName: "ZenoTOF 7600 system"
    },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "products" */ "../views/products.vue")
  },
  {
    path: "/OneOmics",
    name: "OneOmics",
    class: "oneomics",
    meta: {
      productName: "OneOmics Suite"
    },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "products" */ "../views/products.vue")
  }
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
});

export default router;
