<template>
  <div id="navBar" :class="['bg', {visible : showNav} ]">
        
        <button @click="toggleNav">
            <div class="menu top">
            </div>

            <div class="menu mid">
            </div>

            <div class="menu bot">
            </div>

        </button>

        <transition name="fade" mode="out-in">
            <div v-if="showNav" class="smlMenu">MENU</div>
        </transition>

        <ul :class="['linkList', {show : showNav} ]">
            <li @click="toggleNav" v-for="(pg, index) in routes" :class="pg.class" :key="index">
                <div class="icon">
                    <img :src="'/imgs/'+pg.class+'_icon.svg'" :alt="pg.meta.productName">
                </div>
                <router-link @click="resetLensPostition" :to="pg.path">{{pg.meta.productName}}</router-link>
            </li>
        </ul>

        <transition name="fade" mode="out-in">
            <div v-if="!showNav" class="sectionTitle">{{pageName}}</div>
        </transition>

    </div>

</template>

<script>
export default {
  name: "navBar",
    props: {},
    data: function() {
        return {
            // showNav: false
        }
    },
    computed: {
        showNav: function() {
            return this.$store.state.showNav;
        },
        routes: function() {
            return this.$router.options.routes
        },
        pageName: function() {
            return "Home" != this.$route.meta.productName ? this.$route.meta.productName : ""
        }
    },
    methods: {
        toggleNav: function() {
            this.$store.commit("toggleNav");
        },
        resetLensPostition: function() {
            this.$store.commit("resetLensPostition");
        }
    }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
#navBar{
    position:absolute;
    top:0;
    right:0;
    width:50px;
    height:100%;
    border-left:2px solid #fff;
    transition:all .5s;
    z-index:500
}
#navBar.visible{
    width:200px;
    background-color:rgba(0,0,0,.9)
}
#navBar.bg{
    background-color:rgba(0,0,0,.9)
}
#navBar ul{
    padding:0;
    margin:0 0 0 -4rem;
    list-style:none;
    overflow:hidden;
    width:calc(200px + 4rem)
}
#navBar li{
    margin:-2px 0 -2px 4rem;
    border-top:2px solid #fff;
    border-bottom:2px solid #fff;
    display:flex;
    transition:all .3s
}
#navBar li:last-child{
    margin-bottom:0
}
#navBar li:first-child{
    margin-top:0
}
#navBar li a{
    display:inline-block;
    padding:1rem;
    width:199px;
    text-decoration:none
}
#navBar li:hover{
    background:#000;
    margin-left:0
}
#navBar li.zenoTOF:hover,#navBar li.zenoTOF a.router-link-exact-active{
    background:#9cd32c;
    background:linear-gradient(52deg,#249743 10%,#6cba33 46%,#9cd32c 90%)
}
#navBar li.oneomics:hover,#navBar li.oneomics a.router-link-exact-active{
    background:#0499ae;
    background:linear-gradient(52deg,#016489 10%,#0499ae 46%,#08ccc6 90%)
}
#navBar li.biophase:hover,#navBar li.biophase a.router-link-exact-active{
    background:#002F5B;
    background:linear-gradient(52deg,#011B34 10%,#002F5B 46%,#0060C0 90%)
}
#navBar li .icon img {
    width:3rem;
}
#navBar li .icon {
    width:0;
    overflow:hidden;
    display:flex;
    align-items: center;
    justify-content: center;
    background-color:#fff;
    transition:all .3s
}
#navBar li:hover .icon {
    width:3rem
}
#navBar a{
    font-weight:700;
    color:#fff
}
.smlMenu{
    color:#fff;
    font-size:9px;
    margin:3rem .5rem .2rem .5rem
}
.linkList{
    opacity:0;
    transition:opacity .3s;
    pointer-events:none
}
.linkList.show{
    opacity:1;
    pointer-events:all
}
button{
    width:calc(50px - 1.5rem);
    height:calc(50px - 1.5rem);
    background-color:transparent;
    border:none;
    padding:0;
    margin:.75rem;
    position:absolute;
    top:0;
    right:0;
    cursor:pointer
}
button:focus{
    outline:4px solid hsla(0,0%,100%,.2);
    background-color:hsla(0,0%,100%,.2)
}
.menu{
    width:100%;
    height:2px;
    background-color:hsla(0,0%,100%,.9);
    margin:5px auto;
    transition:all .3s;
    transform-origin:center
}
.visible .menu.mid{
    opacity:0
}
.visible .menu.top{
    transform:translateY(7px) rotate(45deg)
}
.visible .menu.bot{
    transform:translateY(-7px) rotate(-45deg)
}
.sectionTitle{
    font-family:Geogrotesque-SmBd,Helvetica,Arial,sans-serif;
    font-style:normal;
    color:#fff;
    font-size:2.7rem;
    width:500px;
    height:50px;
    padding:.9rem 1rem 1rem 1rem;
    display:flex;
    align-items:center;
    position:absolute;
    z-index:0;
    top:100%;
    left:0;
    transform-origin:top left;
    transform:rotate(-90deg)
}
.fade-enter-active,.fade-leave-active{
    transition:opacity .5s ease
}
.fade-enter-from,.fade-leave-to{
    opacity:0
}
</style>
